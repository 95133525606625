import { DownloadIcon } from '@heroicons/react/solid'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import TextLink from '../../components/TextLink'
import { VideoFileFromBackend } from '../../components/VideoContainer'
import Button from '../../components/button'
import { generateDownloadLink } from '../../store/DataSlice'
import { classNames } from '../../utils/utils'

const H1 = ({ children }) => {
  return (
    <h1 className="flex justify-center text-xl md:text-2xl font-bold mb-5">{children}</h1>
  )
}
H1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
}

const H2 = ({ children }) => {
  return (
    <h2 className="flex justify-center text-lg md:text-xl font-bold mb-4">{children}</h2>
  )
}
H2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
}

const LinkToIfU = () => {
  return (
    <>
      The more detailed guide can be found in the
      <TextLink to="/instructions" className="mx-1">Instruction for Use</TextLink>
      page.
    </>
  )
}

const QuickGuide = ({ type, name, className }) => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState(undefined)
  const openQuickGuide = async () => {
    try {
      const { url } = await dispatch(generateDownloadLink(type)).unwrap();
      window.open(url);
    } catch (rejectedValueOrSerializedError) {
      setError(`Downloading ${name} failed, please try again later`);
    }
  }
  return (
    <div className={classNames('w-72', className)}>
      <div className="flex-shrink-0 flex flex-col gap-1">
        <Button
          className="flex flex-row gap-2 font-bold justify-center items-center"
          onClick={openQuickGuide}
          ariaLabel={`Download the ${name} Quick Guide`}
        >
          <span>{name} Quick Guide</span>
          <DownloadIcon className="w-5 h-5" />
        </Button>
        {error && (
          <span className="text-left text-red-600 text-xs">{error}</span>
        )}
      </div>
    </div>
  )
}
QuickGuide.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
}
const QuickGuidesVideos = [
  {
    videoId: 'videoRegistration',
    title: 'App Setup',
    message: 'The quick guide for the registration to and setup of an app provides you with a summary of the necessary steps.',
    button: {
      fileId: 'registration',
      name: 'Registration'
    }
  },
  {
    videoId: 'videoUsage',
    title: 'App Usage',
    message: 'You can also download the Usage Quick Guide for a summary of the necessary steps.',
    button: {
      fileId: 'usage',
      name: 'Usage'
    }
  }
]
const VideoSettings = {
  controls: true,
  width: '100%',
  height: '100%'
}

const PageDashboard = ({ children = null }) => {
  const [activeVideoId, setActiveVideoId] = React.useState('')

  const contentWelcome = (
    <div className="flex-auto">
      <H1>Welcome to the RetInSight Customer Portal!</H1>
      <p className="text-center">
        Thank you for obtaining a license to the RetInSight products.
        <br></br>
        Here you will find information about the practical use of these products.
      </p>
    </div>
  )
  const contentDemoVideoAndQuickGuide = (
    <div className="max-w-3xl mx-auto flex flex-col gap-4 md:gap-10 ">
      {QuickGuidesVideos.map((videoContainer) => (
        <div key={`${videoContainer.videoId}`}>
          <H2>{videoContainer.title}</H2>
          <div className="flex flex-row justify-center">
            <VideoFileFromBackend
              videoId={videoContainer.videoId}
              onActivateVideo={() => setActiveVideoId(videoContainer.videoId)}
              activeVideoId={activeVideoId}
              videoSettings={VideoSettings}
            />
          </div>
          <p className="text-left my-6">
            {videoContainer.message} <LinkToIfU></LinkToIfU>
          </p>
          <div className="flex justify-center w-full">
            <QuickGuide
              type={`${videoContainer.button.fileId}`}
              name={`${videoContainer.button.name}`}
            />
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col gap-y-5 max-w-7xl mx-auto my-5 sm:px-6 lg:px-8">
          <div className="p-5 bg-white shadow rounded-lg">
            {contentWelcome}
          </div>
          <div className="p-5 bg-white shadow rounded-lg">
            {contentDemoVideoAndQuickGuide}
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

PageDashboard.propTypes = {
  children: PropTypes.any,
}

export default PageDashboard
