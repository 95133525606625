const fn = () => {}

const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const pad = (number) => {
  const _num = Number(number)
  if (_num < 10) {
    return `0${_num}`
  }
  return `${_num}`
}

const toISOString = (unixtime, niceView = true) => {
  const _unixtime = new Date(Number(unixtime) * 1000)
  const YMD = `${_unixtime.getUTCFullYear()}-${pad(_unixtime.getUTCMonth() + 1)}-${pad(_unixtime.getUTCDate())}`
  const HMS = `${pad(_unixtime.getUTCHours())}:${pad(_unixtime.getUTCMinutes())}:${pad(_unixtime.getUTCSeconds())}`
  const MS = (_unixtime.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
  return niceView ? `${YMD} ${HMS}` : `${YMD}T${HMS}.${MS}Z`
}

const dateTimeFormat = (unixtime, isoLang = 'en-US') => {
  try {
    return new Intl.DateTimeFormat(isoLang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(unixtime)
  } catch (e) {
    return unixtime
  }
}

const dateToString = (unixtime) => {
  const _unixtime = new Date(Number(unixtime) * 1000)
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const monthNumber = _unixtime.getUTCMonth()
  return `${_unixtime.getUTCFullYear()}-${monthNames[monthNumber].substr(0, 3)}-${pad(_unixtime.getUTCDate())}`
}

export {
  fn,
  timeout,
  classNames,
  toISOString,
  dateTimeFormat,
  dateToString,
  pad,
}
