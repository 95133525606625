import React from 'react'
import TextLink from '../../components/TextLink'

const PrivacyEn = () => {
  return (
    <section>
      <div className="p-3 mx-auto bg-white w-full shadow rounded-lg pb-10">
        <title className="flex justify-center text-3xl py-5">Privacy Notices</title>
        <h2 className="flex justify-center text-xl">Obligation to provide information in accordance with Art 13 and Art 14 GDPR</h2>
        <div className="flex justify-start mt-4 ml-5">
          <TextLink to="/privacy-policy/de" className="text-xl">Read our Privacy Policy in German</TextLink>
        </div>
        <div className="px-2 md:px-5 mx-auto bg-white w-full flex flex-col md:flex-row">
          <div className="text-left" aria-hidden="true">
            <p>Version of 30/04/2022</p>
            <p>The protection of your personal data is of particular concern to us. We therefore process your data exclusively on the basis of the statutory provisions (GDPR, TKG 2003). In this privacy information, we inform you how we ensure this protection and what types of data are processed and for what purpose. Personal data means any information relating to an identified or identifiable natural person, such as your name, address, email addresses, user behavior.</p>

            <title className="flex justify-start mt-8 text-2xl">Controller</title>
            <p><span className="font-bold">RetInSight  GmbH</span>,  Elisabethstraße 13/1/13 in 1010 Vienna, Austria, CEO: Corinna zur Bonsen-Thomas, Handelsgericht Wien, FN 531348w, T: +43-(0)1-5813881, <a href="mailto: corinna.thomas@retinsight.com">corinna.thomas@retinsight.com</a> is responsible for the processing of your personal data in accordance with Art. 4 sec. 7 General Data Protection Regulation.</p>

            <title className="flex justify-start mt-8 text-2xl">Data protection officer</title>
            <p>RetInSight GmbH has appointed the company <span className="font-bold">rs   consulting   GmbH</span>, Buchberggasse 6 in 3400 Klosterneuburg, Austria, CEO: Roland Schopper, Corporate Register Court Korneuburg, FN 411839x, T: +43(2243)22562, <a href="mailto: datenschutz@resultate.at">datenschutz@resultate.at</a> as external data protection officer.</p>

            <title className="flex justify-start mt-8 text-2xl">Data categories and purpose of processing</title>
            <p>The controller collects and processes different categories of personal data, depending on the purpose for the processing. The following sections show the categories of data by purpose of   processing:</p>

            <title className="flex flex-row justify-start mt-8 text-xl font-bold">
              <div>
                3.1	Your visit of our website at
                <a href="https://www.retinsight.com" className="mx-1" target="_blank" rel="noreferrer">www.retinsight.com</a>or
                <a href="https://portal.retinsight.cloud" className="mx-1" target="_blank" rel="noreferrer">portal.retinsight.cloud</a>
              </div>
            </title>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.1.1 Log data of your visit</title>
            <p>When you visit our website, we collect the following personal information transferred from your server:</p>
            <ul className="ml-10 list-disc">
              <li>IP address</li>
              <li>Date and time of the request</li>
              <li>Time zone difference based on Greenwich Mean Time (GMT)</li>
              <li>Content of the request (specific website)</li>
              <li>HTTP-Status-Code</li>
              <li>Volume of data transmitted each time per visit</li>
              <li>Website from which the request comes</li>
              <li>Browser, including language and version</li>
              <li>Operating system and interface</li>
              <li>Visitor&apos;s country of origin</li>
            </ul>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.1.2	Cookies on www.retinsight.com</title>
            <p>When you visit our website, technically necessary cookies are stored on your computer. Cookies are small text files that are stored on your hard drive in conjunction with the browser you are using. They serve to make our offer more user-friendly and effective. Additionally, and as per the relevant info in the “cookie bar”, you as a visitor can choose to accept or decline the use of cookie(s) for our analytics solution “Matomo”.</p>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.1.3	Analytics on www.retinsight.com</title>
            <p>Based on your acceptance, we are using the analytics solution “Matomo” in a self-hosted version. Doing this we can ensure that no data is processed in “the cloud” and we keep full control to secure your privacy.</p>
            <title className="flex justify-start mt-4 text-xl font-bold">3.2	Your contact with us</title>
            <p>If you contact us via a form on the website or by e-mail, your data will be stored with us for six months in order to process the request and in case of follow-up questions. We do not share this data without your consent.</p>
            <title className="flex justify-start mt-4 text-xl font-bold">3.3	Contract</title>
            <p>For the purpose of contract processing, the following personal data will be stored with us: contact person, e-mail address, user credentials, telephone number, contracts and other contractual documents. The data provided by you is necessary for the performance of the contract or for the implementation of pre-contractual measures. Without this data, we cannot conclude the contract with you.</p>
            <title className="flex justify-start mt-4 text-xl font-bold">3.4	Employee data</title>
            <p>The details of the processing of the data of our employees can be found in the document “Employee Data Protection Declaration”, which is accessible for all employees and persons who intend to become an employee.</p>
            <title className="flex justify-start mt-4 text-xl font-bold">3.5	Biometric data in the form of OCT scans</title>
            <p>As a processor, we process biometric data in form of retinal OCT scans. This data will only be transmitted to us in pseudonymized form.</p>
            <p>The privilege of processing personal data goes hand in hand with our responsibility to treat this data ethically correct. We are committed to using data responsibly and transparently. RetInSight strives to adhere to high ethical standards in all areas of the company and to pursue an ethical, value-oriented culture in which problems are addressed quickly and visibly</p>
            <p>RetInSight treats all data in accordance with national and international laws and guidelines, as well as ethical standards and principles.</p>
            <p>RetInSight processes image data that is classified as sensitive personal data, as the OCT images can be used to draw conclusions about the person&apos;s health status. The protection of privacy is a central and sensitive issue both from a regulatory point of view and for RetInSight&apos;s customers and cooperation partners. Retinal images are categorized as biometric data, since the unique vascular structure cannot be changed (similar to a fingerprint) and therefore can only be pseudonymized (not anonymized) according to current interpretation. RetInSight is aware that customer adoption of artificial intelligence (AI) technology is critical and develops and operates these algorithms in accordance with EU Code of Ethics for Trusted AI.</p>
            <p>Pseudonymization means that we as processor cannot assign the collected data to specific patients, and all personally identifiable information (PII) remains with the direct healthcare provider. In addition, full encryption is already included in the development process.</p>
            <p>Strict technical and organizational measures resulting from our data protection impact assessment ensure the highest level of privacy and security. All data is encrypted and processed only within the EU. The data sets are pseudonymized from the beginning and only a limited number of employees can access this data.</p>
            <p>We are aware of our special responsibility and apply the strictest standards to enable the highest level of security and data protection.</p>
            <title className="flex justify-start mt-8 text-2xl">Purpose and legal basis of data processing</title>
            <title className="flex flex-row justify-start mt-8 text-xl font-bold">4.1	Your visit at our website </title>
            <p>We want to enable a user-friendly experience of our website, avoiding any technical faults or malfunctions. We also want to ensure that we do provide the most relevant information, which may require to take into account certain personal information, such as the language in your browser settings or the country from where you are visiting our Services. The legal basis for the presented processing of personal data is our legitimate interest, i.e. Art. 6 sec. 1 lit. f) GDPR.</p>
            <title className="flex flex-row justify-start mt-8 text-xl font-bold">4.2	Your contact with us</title>
            <p>When you contact us, we process your personal data to respond to your request. The legal basis for the processing of your personal data is the initiation of a contractual relationship on the basis of your request or an existing contractual relationship with you and thus Art. 6 sec. 1 b) of the GDPR or the protection of our legitimate interests, i.e. Art. 6 sec. 1 lit. f) GDPR.</p>
            <title className="flex flex-row justify-start mt-8 text-xl font-bold">4.3	Contract</title>
            <p>The data processing is necessary for the fulfilment of the contract and therefore takes place on the basis of the legal provisions of Art 6 (1) lit b of the GDPR.</p>
            <title className="flex flex-row justify-start mt-8 text-xl font-bold">4.4	Your Cookie Consent</title>
            <p>The data processing according to your consent in the “cookie bar” upon your first visit is based on Art. 6 sec. 1 lit. a) GDPR.</p>
            <title className="flex justify-start mt-8 text-2xl">Transfer to 3<span className="align-baseline text-xs">rd</span> parties</title>
            <p>Data is not transferred to any third parties, with the exception of the settled banking institutions/payment service providers for the purpose of processing the payment transactions, to the transport company/shipping company commissioned by us for the delivery of any documents as well as to our tax advisor for the fulfilment of our tax obligations. In specific areas, we cooperate with selected processors with whom we have concluded corresponding agreements in accordance with the GDPR.</p>
            <title className="flex justify-start mt-8 text-2xl">Storage periods for personal data</title>
            <p>Your data will be deleted as soon as you withdraw your consent or in general, as soon as the purpose of the data processing has ceased. In some cases, we are required by law to keep data for a defined period of time. As soon as this period has elapsed, we will delete this data in accordance with the data protection obligations.</p>
            <title className="flex justify-start mt-8 text-2xl">Social-Media Plug-ins</title>
            <p>For privacy reasons, we do not use Social-Media plug-ins.</p>
            <title className="flex justify-start mt-8 text-2xl">Integrated third-party service tools</title>
            <p>Our website does not include third-party service tools.</p>
            <title className="flex justify-start mt-8 text-2xl">Your rights</title>
            <p>You are entitled to access, rectify, delete, restrict, data portability, revocation and opposition with regard to your data stored by us. If you believe that the processing of your data violates data protection law or your data protection claims have otherwise been violated in any way, you can complain to us or the data protection authority, in Austria this is the data protection authority under <a href="www.dsb.gv.at" target="_blank">www.dsb.gv.at</a>.</p>
            <p>Regardless of your rights, please contact us for any queries or suggestions via above mentioned channels (under item “Controller” or “Privacy Officer”) details! We look forward to hearing from you!</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyEn