import React from 'react'
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { LayoutDashboard } from './layouts'
import PageDashboard from './pages/dashboard'
import DeclarationOfConformity from './pages/declarationOfConformity'
import FAQ from './pages/faq'
import FluidMonitor from './pages/fluidMonitor'
import GAMonitor from './pages/gaMonitor'
import GetAccess from './pages/getAccess'
import InstructionsForUse from './pages/instructions'
import Labels from './pages/labels/Labels'
import Privacy from './pages/privacy'
import TcfmLabels from './pages/tcfmLabels'
import TermsAndDPA from './pages/termsAndDPA'
import PrivateRoute from './utils/PrivateRoute'

const Routes = () => (
  <Switch>
    <PrivateRoute path="/" exact>
      <LayoutDashboard>
        <PageDashboard />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/instructions" exact>
      <LayoutDashboard>
        <InstructionsForUse />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/get-access" exact>
      <LayoutDashboard>
        <GetAccess />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/fluid-monitor" exact>
      <LayoutDashboard>
        <FluidMonitor />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/ga-monitor" exact>
      <LayoutDashboard>
        <GAMonitor />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/faq" exact>
      <LayoutDashboard>
        <FAQ />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/labels" exact>
      <LayoutDashboard>
        <Labels />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/privacy-policy/:languageId" exact>
      <LayoutDashboard>
        <Privacy />
      </LayoutDashboard>
    </PrivateRoute>
    <Route path="/terms" exact>
      <LayoutDashboard>
        <TermsAndDPA />
      </LayoutDashboard>
    </Route>
    <PrivateRoute path="/declaration-of-conformity" exact>
      <LayoutDashboard>
        <DeclarationOfConformity />
      </LayoutDashboard>
    </PrivateRoute>
    <Route path="/tcfm-labels" exact>
      <TcfmLabels />
    </Route>
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
)

export default Routes
