import PropTypes from 'prop-types'
import React from 'react'

const PageTitle = ({ title }) => {
  return (
    <header>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-bold leading-tight tracking-tight text-left text-gray-900">{title}</h1>
      </div>
    </header>
  )
}



PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle;

