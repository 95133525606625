import { TrashIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon, RefreshIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImgAccessFluidMonitor from '../../assets/accessFluidMonitor.png';
import Button from '../../components/button';
import CredentialsModalCreate from '../../components/modals/CredentialsModalCreate';
import CredentialsModalDelete from '../../components/modals/CredentialsModalDelete';
import ModalError from '../../components/modals/error/ModalError';
import { createAppwayCredentialsGM, deleteAppwayCredentialsGM, getAppwayCredentialsGM } from '../../store/DataSlice';

import { useSortBy, useTable } from 'react-table';
import PageTitle from '../../components/PageTitle';

const Error = (title, message) => {
  return {
    title: title,
    message: message
  }
}

Error.protoTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}


const unexpectedError = Error('Something went wrong', 'Please try again. If you keep getting an error, please contact RetInSight support.')
const profileNotConfiguredError = Error('Your user profile isn’t configured yet', <p className="my-2">Please contact the RetInSight support <a className="text-blue-600 underline" href="mailto:support@retinsight.com">support@retinsight.com</a> to fix this issue - sorry for the inconvenience!</p>)
const unauthorizedError = Error('Session has expired', <p className="my-2">Please sign in.</p>)

const GAMonitor = ({
  children = null,
}) => {
  const [appwayCredentials, setAppwayCredentials] = React.useState([])
  const [credentialsToDelete, setCredentialsToDelete] = React.useState(null)
  const [createdCredentials, setCreatedCredentials] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Customer ID',
        accessor: 'id'
      },
      {
        Header: 'Description',
        accessor: 'name'
      },
      {
        Header: 'Created at',
        accessor: 'createdAt'
      },
      {
        Header: 'Actions',
        accessor: 'action',
        disableSortBy: true,
        Cell: (value) => (
          <div className="w-full text-center">
            <button href="#" alt={`Delete access for ${value.row.values.name}`} onClick={() => setCredentialsToDelete(value.row.values)} >
              <TrashIcon className="text-red-500 w-6 h-6" />
            </button>
          </div>
        )
      },
    ],
    []
  )
  const dispatch = useDispatch()

  const tableInstance = useTable({
    columns: columns,
    data: appwayCredentials,
    initialState: {
      sortBy: [
        {
          id: 'createdAt',
          desc: true
        }
      ]
    }
  }, useSortBy)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const getErrorFromCode = (statusCode) => {
    let _error = null
    switch (statusCode) {
      case 451:
        _error = profileNotConfiguredError
        break;
      case 401:
        _error = unauthorizedError
        break;
      default:
        _error = unexpectedError
        break;
    }
    return _error
  }

  const fetchData = async () => {
    setLoading(true)
    dispatch(getAppwayCredentialsGM()).then((result) => {
      if (result.error) {
        setError(getErrorFromCode(result.payload?.errorCode))
      } else {
        setAppwayCredentials(result.payload)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const confirmCreate = data => {
    if (data === undefined) return
    setLoading(true)
    dispatch(createAppwayCredentialsGM(data.name))
      .then((result) => {
        if (result.error) {
          setCreateModalIsOpen(false)
          setError(getErrorFromCode(result.payload?.errorCode))
        } else {
          setCreatedCredentials(result.payload)
          fetchData()
        }
      })
      .catch((error) => {
        setError(unexpectedError)
      }).finally(() => {
        setLoading(false)
      })
  }

  const confirmDelete = () => {
    setLoading(true)
    dispatch(deleteAppwayCredentialsGM(credentialsToDelete.id))
      .then((result) => {
        if (result.error) {
          setError(getErrorFromCode(result.payload?.errorCode))
        } else {
          setTimeout(false)
          fetchData()
        }
      })
      .catch((error) => {
        setError(unexpectedError)
        setLoading(false)
      }).finally(() => {
        setCredentialsToDelete(null)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const Modals = () => {
    return <>
      <ModalError
        open={error !== null}
        onClose={() => setError(null)}
        title={error?.title}
        content={error?.message}
      ></ModalError>
      <CredentialsModalCreate
        open={createModalIsOpen}
        onClose={(e) => {
          if (!loading) {
            setCreateModalIsOpen(false)
            setCreatedCredentials(null)
          }
        }}
        onCreate={confirmCreate}
        credentials={createdCredentials}
        title="Generate ID & Password"
        content="Please assign a description to the credentials you are about to generate."
        productName="GA Monitor"
      ></CredentialsModalCreate>
      {
        credentialsToDelete &&
        <CredentialsModalDelete
          open={credentialsToDelete !== null}
          onClose={() => !loading && setCredentialsToDelete(null)}
          onDelete={confirmDelete}
          loading={loading}
          title={`Are you sure you want to delete access for ${credentialsToDelete.name}?`}
          productName="GA Monitor"
        ></CredentialsModalDelete>
      }
    </>
  }
  const userData = useSelector((state) => state.user.data)
  return (
    <>
      {
        loading && <div className="fixed z-20 top-0 inset-0 flex justify-center items-center border-gray-800 bg-gray-700 bg-opacity-75">
          <RefreshIcon className="self-center text-gray-100 flip-horizontal w-16 h-16 bg-transparent rounded-md spin-back" />
        </div>
      }
      <div className="w-full">
        <PageTitle title="Get Access / GA Monitor" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-6">
          <div className="p-5 mx-auto bg-white w-full shadow rounded-lg">
            {userData.groups.includes('subscription:hegm') ? <>
              <div className="w-full text-2xl">How to get access to GA Monitor?</div>
              <div className="mt-5 p2- md:p-5 mx-auto bg-white w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/3">
                  <p className="text-justify px-2">
                    Please click the button ”Generate ID & Password” below in order to get your <strong>Customer ID + Customer
                      Password</strong> for the GA Monitor App in Heyex/Appway.<br /><br />The credentials will be used to configure
                    the GA Monitor App. Once the Customer ID and the Password are generated and displayed in the pop-up, make sure
                    to copy them by clicking on the respective fields and insert them in Heyex&apos;s ”Configure App” dialog.
                  </p>
                  <div className="visible md:invisible md:h-0 mt-4">
                    <img src={ImgAccessFluidMonitor} alt="Screen capture Access to GA Monitor app" className="mx-auto" />
                  </div>
                  <div className="mt-4 py-3 md:p-3">
                    <Button className="w-60" onClick={() => setCreateModalIsOpen(true)}>
                      Generate ID & Password
                    </Button>
                  </div>
                </div>
                <div className="w-full md:w-2/3 invisible md:visible h-0 md:h-auto">
                  <img src={ImgAccessFluidMonitor} alt="Screen capture Access to GA Monitor app" className="mx-auto" />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="p-1.5 w-full inline-block align-middle">
                  <div className="overflow-x-auto overflow-y-hidden border rounded-lg">
                    <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        {headerGroups.map(headerGroup => (
                          <tr key={`header-row-${headerGroup.id}`}>
                            {headerGroup.headers.map(column => {
                              return (
                                <th
                                  key={`header-column-${column.id}`}
                                  {...column.getHeaderProps(column.getSortByToggleProps())}
                                  className={`px-6 py-3 text-xs font-bold text-${column.id === 'action' ? 'center' : 'left'} text-gray-900 uppercase`}
                                >
                                  <span className="inline-flex items-center">
                                    {column.render('Header')}
                                    {column.isSorted ? (column.isSortedDesc ? (
                                      <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 hover:bg-gray-300">
                                        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                                      </span>
                                    ) :
                                      <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 hover:bg-gray-300">
                                        <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                                      </span>) : ''}
                                  </span>
                                </th>
                              )
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()} className="divide-y divide-gray-200 bg-white">
                        {
                          rows.map(row => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()} key={row.values.id} >
                                {
                                  row.cells.map(cell => (
                                    <td {...cell.getCellProps()} key={`${row.id}-${cell.column.id}`} className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap text-left">
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                  )
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </> : <div>
              <div className="w-full text-2xl">You do not have access to GA Monitor</div>
              <p className="text-sm my-2">Please reach out to <a href="mailto:contact@retinsight.com">contact@retinsight.com</a> if you wish to use this product.</p>
            </div>}
          </div>
        </div>
      </div>
      {children}
      <Modals></Modals>
    </>
  )
}

GAMonitor.propTypes = {
  children: PropTypes.any,
}

export default GAMonitor
