import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FluidMonitorLogo from '../../assets/svg/fluid_monitor_logo.svg';
import GAMonitorLogo from '../../assets/svg/ga_monitor_logo.svg';
import PageTitle from '../../components/PageTitle';

const GetAccess = ({
  children = null,
}) => {
  const userData = useSelector((state) => state.user.data)

  return (
    <>
      <div className="w-full">
        <PageTitle title="Get Access" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="border-b border-gray-200 bg-white px-4 py-7 sm:px-6 relative shadow rounded-lg">
            {userData.groups.includes('subscription:hefm') ? <></> : <div className="rounded-lg absolute w-full h-full opacity-75 left-0 top-0 bg-gray-900 text-white flex flex-col items-center justify-center">
              <h1 className="text-lg">You do not have access to Fluid Monitor</h1>
              <p className="text-sm my-2 text-white">Please reach out to contact@retinsight.com if you wish to use this product.</p>
            </div>}
            <div className="-mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="mt-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12"
                      src={FluidMonitorLogo}
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-base text-left font-semibold text-gray-900">Fluid Monitor</h3>
                    <p className="text-sm text-gray-500 m-0">nAMD (neovascular age-related macular degeneration)
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0">
                <Link to="/fluid-monitor" className="w-30 bg-indigo-600 hover:bg-indigo-500 hover:text-white px-4 p-2 text-white rounded-md" >
                  Get Access
                </Link>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-200 bg-white px-4 mt-5 py-7 sm:px-6 relative shadow rounded-lg">
            {userData.groups.includes('subscription:hegm') ? <></> : <div className="rounded-lg absolute w-full h-full opacity-75 left-0 top-0 bg-gray-900 text-white flex flex-col items-center justify-center">
              <h1 className="text-lg">You do not have access to GA Monitor</h1>
              <p className="text-sm my-2 text-white">Please reach out to contact@retinsight.com if you wish to use this product.</p>
            </div>}
            <div className="-mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="mt-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12"
                      src={GAMonitorLogo}
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-base text-left font-semibold text-gray-900">GA Monitor</h3>
                    <p className="text-sm text-gray-500 m-0">Geographic Atrophy in Age-related Macular Degeneration
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 mt-4 flex flex-shrink-0">
                <Link to="/ga-monitor" className="w-30 bg-indigo-600 hover:bg-indigo-500 hover:text-white px-4 p-2 text-white rounded-md" >
                  Get Access
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

GetAccess.propTypes = {
  children: PropTypes.any,
}

export default GetAccess
