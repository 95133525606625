import { RefreshIcon } from '@heroicons/react/solid'
import PropTypes from 'prop-types'
import React from 'react'
import { classNames } from '../utils/utils'

const Button = ({children, onClick, className, ariaLabel = undefined}) => {
  const [ pending, setPending ] = React.useState(false)

  return (
    <button
      disabled={pending}
      onClick={async (event) => {
        event.preventDefault();
        setPending(true);
        await onClick(event);
        setPending(false);
      }}
      className={classNames('px-4 p-2 bg-indigo-600 hover:bg-indigo-500 text-white rounded-md cursor-pointer disabled:cursor-wait disabled:opacity-60', className)}
      aria-label={ariaLabel}
    >
      { (pending) ? (
        <div className="flex flex-row justify-center content-center">
          <RefreshIcon className="text-gray-300 flip-horizontal w-6 h-6 bg-transparent spin-back" />
        </div>
      ) : (
        children
      )
      }
    </button>
  )
}



Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
}

export default Button;

