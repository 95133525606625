import PropTypes from 'prop-types'
import React from 'react'
import PageTitle from '../../components/PageTitle'

const TermsAndDPA = ({
  children = null,
}) => {
  return (
    <>
      <div className="w-full">
        <PageTitle title="Terms of Use & DPA" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className=" p-5 mx-auto bg-white w-full shadow rounded-lg">
            <title className="flex justify-start text-xl font-bold">Terms of Use</title>
            <div className="mx-auto bg-white w-full flex flex-col md:flex-row">
              <div className="text-left py-3">
                <p>The Terms of Use for Fluid Monitor are covered in the Customer Agreement, which will be or has been provided to you by RetInSight.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="my-5 p-5 mx-auto bg-white w-full shadow rounded-lg">
            <title className="flex justify-start text-xl font-bold">Data Processing Agreement</title>
            <div className="mx-auto bg-white w-full flex flex-col md:flex-row">
              <div className="text-left py-3">
                <p>The Data Processing Agreement for Fluid Monitor will be or has been provided to you with the Customer Agreement.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

TermsAndDPA.propTypes = {
  children: PropTypes.any,
}

export default TermsAndDPA
