import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { classNames } from '../utils/utils'


const TextLink = ({to, children, className}) => {
  return (
    <Link to={to} className={classNames('cursor-pointer font-bold text-indigo-600 hover:text-indigo-500', className)}>
      {children}
    </Link>
  )
}

TextLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default TextLink;



