import { RefreshIcon } from '@heroicons/react/solid'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { generateDownloadLink } from '../store/DataSlice'

const VideoFileFromBackend = ({
  videoId,
  onActivateVideo,
  activeVideoId,
  videoSettings
}) => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(undefined);
  const [url, setUrl] = React.useState(videoId);
  const { controls, width, height } = videoSettings;

  const getUrl = useCallback( async() => {
    try {
      setError(undefined);
      setUrl(undefined);
      const {url} = await dispatch(generateDownloadLink(videoId)).unwrap();
      setUrl(url);
    } catch (rejectedValueOrSerializedError) {
      setError('Downloading failed, please try refreshing the page');
    }
  }, [dispatch, videoId])

  useEffect(() => {
    getUrl()
  }, [getUrl])

  return (
    <>
      { error ? (
        <span className="text-left text-red-600 text-xs">{error}</span>
      ): (
        (!url) ? (
          <div className="flex flex-row justify-center content-center">
            <RefreshIcon className="text-gray-500 flip-horizontal w-12 h-12 bg-transparent spin-back" />
          </div>
        ) : (
          <ReactPlayer
            url={url}
            controls={controls}
            width={width}
            height={height}
            playing={(activeVideoId === videoId) ? true : false}
            onPlay={() => onActivateVideo(videoId)}
            onError={() => setTimeout(getUrl, 5000)}
          />
        )
      )}
    </>
  )
}
VideoFileFromBackend.propTypes = {
  videoId: PropTypes.string,
  onActivateVideo: PropTypes.func,
  activeVideoId: PropTypes.string,
  videoSettings: PropTypes.object
}


export { VideoFileFromBackend }
