import { RefreshIcon } from '@heroicons/react/outline'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import Login from '../components/auth/Login'
import { resetToken, setToken, setUserEmailAddress, setUserGroups } from '../components/auth/store/userSlice'
import Footer from '../components/footer/Footer'
import { LayoutFull } from '../layouts'

const PrivateRoute = ({ children, path, exact, }) => {
  // TODO: (change token in 'isSignedIn'?)
  const token = useSelector((state) => state.user.token)
  const dispatch = useDispatch()
  const [isInitiated, setIsInitiated] = React.useState(false)

  React.useEffect(() => {
    if (!token) {
      const getToken = async () => {
        const user = await Auth.currentAuthenticatedUser().catch(() => {})

        if (user) {
          const token = user?.signInUserSession?.accessToken?.jwtToken
          const groups = user?.signInUserSession?.accessToken?.payload['cognito:groups']
          const email = user?.attributes?.email

          dispatch(setToken(token))
          dispatch(setUserEmailAddress(email))
          dispatch(setUserGroups(groups))
        } else {
          console.log('We don’t have user!')
          dispatch(resetToken())
        }
      }
      getToken()
      setIsInitiated(true)
    }
  }, [dispatch, isInitiated, token])

  return (
    !isInitiated && !token
      ? (
        <LayoutFull>
          <>
            <div className="min-h-screen flex items-center justify-center">
              <RefreshIcon className="self-center text-gray-300 flip-horizontal w-16 h-16 bg-transparent rounded-md spin-back" />
            </div>
            <div className="fixed w-full h-16">
              <Footer />
            </div>
          </>
        </LayoutFull>
      )
      : (
        token
          ? (
            <Route path={path} exact={exact}>{children}</Route>
          )
          : (
            <LayoutFull>
              <Login />
            </LayoutFull>
          )
      )
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string,
  exact: PropTypes.bool,
  isInitiated: PropTypes.bool,
}

export default PrivateRoute
