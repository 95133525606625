import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yupPassword from 'yup-password'
import { Auth } from 'aws-amplify'
import { fn } from '../../utils/utils'
import { ModalError, ModalSuccess } from '../modals'
import SvgLoader from '../svg/Loader'
import PasswordInput from './PasswordInput'

// import { ArrowLeftIcon } from '@heroicons/react/outline'
// import { timeout } from '../../utils/utils'

yupPassword(yup)

const SetPassword = ({
  onSuccess = fn,
  user = {},
}) => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPwSaved, setPwSaved] = useState(false)
  // const [validationErrors, setValidationErrors] = useState([])

  const schema = yup.object().shape({
    Password: yup.string().password().required(),
  })

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    criteriaMode: 'all',
  })

  const validatePassword = async (pw) => {
    try {
      await schema.validate({ Password: pw }, { abortEarly: false })
      return true
    } catch (e) {
      // setValidationErrors(e.errors)
      // [
      //   'Password must be at least 8 characters',
      //   'Password must contain at least 1 uppercase letter',
      //   'Password must contain at least 1 number',
      //   'Password must contain at least 1 symbol',
      // ]
    }
    return false
  }

  const onSubmit = async data => {
    // console.log("#######")
    // console.log(data)
    setIsLoading(true)
    try {
      // await timeout(1000)
      const pwIsOk = await validatePassword(data.Password)
      // setPwSaved(true)
      // console.log("--- user")
      // console.log(user)
      // console.log("--- /user")
      // console.log(setPwSaved)
      if (pwIsOk) {
        const newPwIsSaved = await Auth.completeNewPassword(user, data.Password)
        // console.log("--- newPwIsSaved:")
        // console.log(newPwIsSaved)
        // console.log("--- /newPwIsSaved")
        if (newPwIsSaved) {
          setPwSaved(true)
        }
      }
    } catch (e) {
      // console.log("### ERROR")
      // console.log(e)
      // console.log(e.code)
      // console.log(e.message)
      // e.code could be "NotAuthorizedException" or 500 or 404 or...
      setIsLoading(false)
      setIsError(true)
    }
    setIsLoading(false)
  }

  const allValidationErrors = errors?.Password?.types
    ? Object.values(errors.Password.types)
    : []

  // const passwordCanNotBeSent = isLoading || allValidationErrors.length > 0 || validationErrors.length > 0
  const passwordCanNotBeSent = isLoading || allValidationErrors.length > 0

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
      {/* <div className="text-left">
        <a
          onClick={goBack}
          className="flex flex-row cursor-pointer text-sm text-blue-300 hover:underline"
        >
          <ArrowLeftIcon className="flex-shrink-0 h-5 w-5 text-blue-300 hover:underline" aria-hidden="true" /><span className="ml-1">Go Back</span>
        </a>
      </div> */}
      <h2 className="select-none mt-0 mb-6 text-center text-2xl font-bold text-gray-900">Set new password</h2>

      <p>You need to set a new password. The temporary password becomes invalid.</p>

      <div className="relative w-full mb-3">
        <PasswordInput
          registerFunc={register}
          isLoading={isLoading}
          testIds={{
            passwordInput: 'login.input.set_new_pw',
            eyeIcon: 'login.input.set_new_pw.icon_eye'
          }}></PasswordInput>
      </div>

      <div className="pt-4">
        <button
          type="submit"
          disabled={passwordCanNotBeSent}
          className="select-none w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-12 disabled:opacity-50"
          data-testid="login.button.save_new_password"
        >
          {isLoading
            ? <SvgLoader />
            : 'Save'
          }
        </button>
      </div>

      {/* <div className="pt-0">
        {validationErrors.map((item, i) => {
          return (
            <div
              key={`validation_error_${i}`}
              style={{ margin: 0 }}
              className="text-xs text-red-600"
            >
              {item}
            </div>
          )
        })}
      </div> */}

      <div className="pt-0">
        {errors?.Password?.types && Object.values(errors.Password.types).map((key, i) => {
          return (
            <div
              key={`validation_error_${i}`}
              style={{ margin: 0 }}
              className="text-xs text-red-600"
            >
              {key}
            </div>
          )
        })}
      </div>


      <ModalError
        open={isError}
        onClose={() => setIsError(false)}
        title="Set password error"
        content="There was an error. Please contact the support."
      />
      <ModalSuccess
        open={!!isPwSaved}
        onClose={() => onSuccess()}
        title="Set Password succeded"
        content={
          <div>
            <h3>Your new password was saved!</h3>
            <div>Please login now with your new password.</div>
          </div>
        }
      />
    </form>
  )
}

SetPassword.propTypes = {
  onSuccess: PropTypes.func,
  user: PropTypes.object,
}

export default SetPassword
