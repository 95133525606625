import PropTypes from 'prop-types';
import React from 'react';
import HEFMFooter from '../../assets/labels/hefm_footer.png';
import HEFMHeader from '../../assets/labels/hefm_header.png';
import HEGMFooter from '../../assets/labels/hegm_footer.png';
import HEGMHeader from '../../assets/labels/hegm_header.png';
import PageTitle from '../../components/PageTitle';

const Labels = () => {

  return (
    <>
      <div className="w-full">
        <PageTitle title="Labels of RetInSight Products" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="p-5 mx-auto bg-white w-full  shadow rounded-lg">
            <div className="p2- md:p-5 mx-auto bg-white w-full flex flex-col text-left">
              <div>
                <h1 className="text-xl font-bold pb-7">Fluid Monitor Label</h1>
                <div className="flex flex-col md:flex-row items-center align-center">
                  <img className="h-full w-full md:w-1/3" src={HEFMHeader} alt="Device Label"></img>
                  <img className="h-full w-full md:w-2/3" src={HEFMFooter} alt="Device Label"></img>
                </div>
              </div>
              <div className="border-t mt-4">
                <h1 className="text-xl font-bold py-7">GA Monitor Label</h1>
                <div className="flex flex-col md:flex-row items-center align-center">
                  <img className="h-full w-full md:w-1/3" src={HEGMHeader} alt="Device Label"></img>
                  <img className="h-full w-full md:w-2/3" src={HEGMFooter} alt="Device Label"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Labels.propTypes = {
  children: PropTypes.any,
}

export default Labels
