import React from 'react'
import { Link } from 'react-router-dom'
import RetinSightLogoBall from '../../assets/svg/ris_logo_ball.svg'

const Footer = () => {
  const footerMenu = [
    { name: 'Terms of Use & DPA', linkTo: '/terms' },
    { name: 'Labels', linkTo: '/labels' },
    { name: 'Declaration of Conformity', linkTo: '/declaration-of-conformity' },
    { name: 'Privacy Policy', linkTo: '/privacy-policy/en' },
  ]

  return (
    <div className="w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center border-t pt-5 mt-10 border-gray-300">
        <div className="leading-3 p-2 pt-4 w-full flex flex-col md:flex-row">
          <div className="flex justify-center align-center md:justify-end w-full md:w-1/6">
            <img
              src={RetinSightLogoBall}
              className="h-20"
              alt="RetinSight • Fluid Monitor Customer Portal"
            />
          </div>
          <div className="flex flex-col md:flex-row text-left w-full md:w-5/6">
            <div className="text-left w-full md:w-3/6">
              <h1 className="text-lg ml-4 md:ml-20">Contact</h1>
              <div className="text-sm m-5 md:ml-20">
                <ul>
                  <li className="my-2">RetInSight GmbH</li>
                  <li className="my-2">Elisabethstraße 13/1/13</li>
                  <li className="my-2">1010 Vienna, Austria</li>
                  <li className="my-2">
                    <a className="text-gray-900 hover:text-gray-700 hover:underline my-2" href="mailto:support@retinsight.com">
                      support@retinsight.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="text-left w-full md:w-3/6">
              <h1 className="text-lg ml-4 md:ml-14">Links</h1>
              <div className="text-sm m-5 md:ml-14 text-gray-900">
                <ul className="footer-links">
                  {footerMenu.map((item) => {
                    return (
                      <li key={item.name} className="text-gray-900 hover:text-gray-700 my-2">
                        <Link
                          to={item.linkTo}>
                          {item.name}
                        </Link>
                      </li>
                    )
                  })}
                  <li className="hover:underline"><a href="https://retinsight.com/" target="_blank" rel="noreferrer">Company Website</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-6 pb-6 ">
        <h1 className="text-sm text-gray-400">© {new Date().getFullYear()} RetInSight, All Rights Reserved</h1>
      </div>
    </div>
  )
}

export default Footer
