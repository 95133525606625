import { PaperClipIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';
import RetLogo from '../../assets/RetInSight_Logo_Color.png';
import FluidMonitorLogo from '../../assets/svg/fluid_monitor_logo.svg';
import TCFMIfUENGB from '../../assets/topcon/ifus/IfU_en_gb.pdf';
import TCFMIfUEN from '../../assets/topcon/ifus/IfU_en.pdf';
import TCFMIfUDE from '../../assets/topcon/ifus/IfU_de.pdf';
import TCFMIfUES from '../../assets/topcon/ifus/IfU_es.pdf';
import TCFMIfUFR from '../../assets/topcon/ifus/IfU_fr.pdf';
import TCFMIfUIT from '../../assets/topcon/ifus/IfU_it.pdf';
import TCFMFooter from '../../assets/topcon/labels/tcfm_footer.png';
import TCFMHeader from '../../assets/topcon/labels/tcfm_header.png';
import PageTitle from '../../components/PageTitle';

const TcfmLabels = ({
  children = null,
}) => {
  const fmLanguagesList = [
    { 'filePath': TCFMIfUENGB, 'longName': 'English (GB)' },
    { 'filePath': TCFMIfUEN, 'longName': 'English (EU/CH)' },
    { 'filePath': TCFMIfUDE, 'longName': 'Deutsch (EU/CH)' },
    { 'filePath': TCFMIfUFR, 'longName': 'Français (EU/CH)' },
    { 'filePath': TCFMIfUIT, 'longName': 'Italiano (EU/CH)' },
    { 'filePath': TCFMIfUES, 'longName': 'Español (EU/CH)' }
  ];

  return (
    <>
      <div className="flex flex-col md:flex-row items-center align-middle justify-between max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="flex flex-row md:flex-row">
          <div className="py-5">
            <img
              className="h-10 w-10"
              src={FluidMonitorLogo}
              alt=""
            />
          </div>
          <div className="mx-auto max-w-7xl py-6 px-2 align-middle">
            <h1 className="text-xl font-bold">Fluid Monitor</h1>
          </div>
        </div>
        <img
          className="h-16"
          src={RetLogo}
          alt=""
        />
      </div>
      <div as="nav" className="w-full">
        <PageTitle title="Instruction for Use" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="p-5 sm:p-6 mx-auto bg-white w-full shadow rounded-lg">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-col flex-wrap sm:flex-nowrap">
                <div className="mt-4 flex w-full">
                  <dd className="mt-2 text-sm text-white sm:col-span-2 sm:mt-0 w-full">
                    <ul className="divide-y divide-white/10 rounded-md border border-white/20">
                      {fmLanguagesList.map((language) => {
                        return (
                          <li key={language.filePath}
                            onClick={() => window.open(language.filePath, '_blank', 'noopener,noreferrer')}
                            className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 cursor-pointer hover:bg-gray-100">
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium text-gray-900">{language.longName}</span>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <PageTitle title="Labels" />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="p-5 mx-auto bg-white w-full  shadow rounded-lg">
          <div className="p2- md:p-5 mx-auto bg-white w-full flex flex-col text-left">
            <div>
              <div className="flex flex-col md:flex-row items-center align-center">
                <img className="h-full w-full md:w-1/3" src={TCFMHeader} alt="Device Label"></img>
                <img className="h-full w-full md:w-2/3" src={TCFMFooter} alt="Device Label"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-6 pb-6 ">
        <h1 className="text-sm text-gray-400">© {new Date().getFullYear()} RetInSight, All Rights Reserved</h1>
      </div>
    </>
  )
}

TcfmLabels.propTypes = {
  children: PropTypes.any,
}

export default TcfmLabels
