import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yupPassword from 'yup-password'
import ReactCodeInput from 'react-code-input'
import { Auth } from 'aws-amplify'
import { fn } from '../../utils/utils'
// import { timeout } from '../../utils/utils'
import { ModalError, ModalSuccess } from '../modals'
import SvgLoader from '../svg/Loader'
import PasswordInput from './PasswordInput'

yupPassword(yup)

const VerifyCode = ({
  codeIsVerified = fn,
  emailAddress = null,
}) => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState('')
  const [isCodeSent, setIsCodeSent] = useState(false)
  // const [isNewPwReadable2, setIsNewPwReadable2] = useState(false)
  // const [validationErrors, setValidationErrors] = useState([])

  const schema = yup.object().shape({
    Password: yup.string().password().required(),
  })

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    criteriaMode: 'all',
  })

  const validatePassword = async (pw) => {
    try {
      await schema.validate({ Password: pw }, { abortEarly: false })
      return true
    } catch (e) {
      // setValidationErrors(e.errors)
      // [
      //   'Password must be at least 8 characters',
      //   'Password must contain at least 1 uppercase letter',
      //   'Password must contain at least 1 number',
      //   'Password must contain at least 1 symbol',
      // ]
    }
    return false
  }

  const onSubmit = async data => {
    // TODO: validate data like email address?
    const pwIsOk = await validatePassword(data.Password)
    if (!pwIsOk || code.length !== 6 || !data?.Password || !emailAddress) {
      setIsError(true)
      return
    }

    setIsLoading(true)
    // TEST:
    // await timeout(3000)
    // setIsCodeSent(true)
    try {
      await Auth.forgotPasswordSubmit(emailAddress, code, data?.Password)
      setIsCodeSent(true)
    } catch (error) {
      setIsError(true)
    }
    setIsLoading(false)
  }

  const allValidationErrors = errors?.Password?.types
    ? Object.values(errors.Password.types)
    : []

  const passwordCanNotBeSent =
    isLoading
    || allValidationErrors.length > 0
    // || validationErrors.length > 0
    || code.length < 6
    || typeof errors.Password !== 'undefined'

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
      <h2 className="select-none mt-0 mb-6 text-center text-2xl font-bold text-gray-900">Code verification</h2>
      <div>
        <label htmlFor="username" className="select-none block text-sm font-medium text-black">
          Please enter the code from the email in order to change your password
        </label>
        <div className="flex mt-4 justify-center" data-testid="login.verify_code.code.input.container">
          <ReactCodeInput
            type="number"
            fields={6}
            inputMode="numeric"
            onChange={setCode}
            className="appearance-none flex text-3xl text-center"
            inputStyle={{
              textAlign: 'center',
              margin: '0 3px',
              padding: '8px 8px',
              height: '4rem',
              width: '3rem',
              fontSize: '30px',
              fontWeight: '700',
              borderRadius: '0.25rem',
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            }}
          />
        </div>
      </div>

      <hr />

      <div className="relative w-full">
        <PasswordInput
          registerFunc={register}
          isLoading={isLoading}
          label="New password"
          testIds={{
            passwordInput: 'login.verify.input.new_pw',
            eyeIcon: 'login.verify.input.set_new_pw.icon_eye'
          }}></PasswordInput>
      </div>

      {/*
        Why it’s bad practice to add a 2nd password field? See:
        https://cxl.com/blog/password-ux/
      */}
      {/* <div className="relative w-full" style={{marginTop: '0.5rem'}}>
        <label htmlFor="new_password2" className="select-none text-sm text-gray-400">Confirm new password</label>
        <input
          {...register('new_password2', {
            required: true,
            validate: (value) => {
	            // trigger('password_repeat')
              console.log('### VALIDATE PW2')
              console.log(value)
              return true;
	          },
          })}
          name="new_password2"
          readOnly={isLoading}
          className="appearance-none border border-gray-300 p-4 placeholder-gray-400 text-black bg-white rounded text-md shadow-md focus:outline-none focus:ring-indigo-500 w-full"
          placeholder="New password2"
          type={isNewPwReadable2 ? "text" : "password"}
          data-testid="login.verify.input.new_pw2"
        />
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          onClick={() => setIsNewPwReadable2(!isNewPwReadable2)}
        >
          <p className="group mt-5 cursor-pointer">
            {isNewPwReadable
              ? <EyeOffIcon className="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-indigo-700" aria-hidden="true" />
              : <EyeIcon className="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-indigo-700" aria-hidden="true" />
            }
          </p>
        </div>
      </div> */}

      <div className="pt-4">
        <button
          type="submit"
          disabled={passwordCanNotBeSent}
          className="select-none w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-12 disabled:opacity-50"
          data-testid="login.button.confirm"
        >
          {isLoading
            ? <SvgLoader />
            : 'Confirm'
          }
        </button>
      </div>

      {/* <div className="pt-0">
        {validationErrors.map((item, i) => {
          return (
            <div
              key={`validation_error_${i}`}
              style={{ margin: 0 }}
              className="text-xs text-red-600"
            >
              {item}
            </div>
          )
        })}
      </div> */}

      <div className="pt-0">
        {errors?.Password?.types && Object.values(errors.Password.types).map((key, i) => {
          return (
            <div
              key={`validation_error_${i}`}
              style={{ margin: 0 }}
              className="text-xs text-red-600"
            >
              {key}
            </div>
          )
        })}
      </div>

      <ModalError
        open={isError}
        onClose={() => setIsError(false)}
        title="Code verification error"
        content="There was an error. Please verify the code or contact the support."
      />
      <ModalSuccess
        open={isCodeSent}
        onClose={codeIsVerified}
        title="Code and password accepted!"
        content={
          <div>
            <div className="my-2">You can now login with your new password!</div>
          </div>
        }
      />
    </form>
  )
}

VerifyCode.propTypes = {
  codeIsVerified: PropTypes.func,
  emailAddress: PropTypes.string,
}

export default VerifyCode
