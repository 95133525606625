import {
  Auth,
} from 'aws-amplify'
import React, { useState, /*useRef,*/ /*useEffect,*/ /*useMemo,*/ } from 'react'
import { useForm } from 'react-hook-form'
import { /*useSelector,*/ useDispatch } from 'react-redux'
import ModalError from '../modals/error/ModalError'
import SvgLoader from '../svg/Loader'
import PasswordInput from './PasswordInput'
import ResetPassword from './ResetPassword'
import SetPassword from './SetPassword'
import VerifyCode from './VerifyCode'
import {
  // resetToken,
  setToken,
  setUserEmailAddress,
  setUserGroups
} from './store/userSlice'
import './styles.scss'

const Login = () => {
  Auth.configure({ storage: sessionStorage })
  localStorage.clear()

  const dispatch = useDispatch()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // 'Login', 'ResetPassword', 'VerifyCode', 'SetPassword'
  const [showContent, setShowContent] = useState({ type: 'Login' })
  // const [showContent, setShowContent] = useState({type: 'VerifyCode'})
  // const [showContent, setShowContent] = useState({type: 'SetPassword'})

  // const token = useSelector((state) => state.user.token)
  // const [fields, handleFieldChange] = useFormFields({
  //   email: '',
  //   password: ''
  // })

  // console.log("### Login")
  // console.log(token)

  // const refPassword = useRef(null)

  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // console.log("##### onSubmit")
    // console.log(data)
    // console.log("##### /onSubmit")
    setIsLoading(true)

    try {
      const userObj = await Auth.signIn(data.username, data.password)
      console.log('### SUCCESS')
      if (
        userObj?.challengeName === 'NEW_PASSWORD_REQUIRED'
        || userObj?.challengeName === 'FORCE_CHANGE_PASSWORD'
      ) {
        /*
          AWS stuff: If a user is added Cognito requires to set a new one.
        */
        // const { requiredAttributes } = userObj.challengeParam

        // console.log("### -> NEW_PASSWORD_REQUIRED")
        // console.log(requiredAttributes)
        // console.log("----------------------------")

        setIsLoading(false)
        return setShowContent({
          type: 'SetPassword',
          data: {
            user: userObj,
          }
        })
      }
      const user = await Auth.currentAuthenticatedUser().catch(() => {})
      // console.log("### USER")
      // console.log(user)
      const token = user?.signInUserSession?.accessToken?.jwtToken
      const groups = user?.signInUserSession?.accessToken?.payload['cognito:groups']
      const email = user?.attributes?.email

      setIsLoading(false)
      // order matters!
      dispatch(setToken(token))
      dispatch(setUserEmailAddress(email))
      dispatch(setUserGroups(groups))

    } catch (e) {
      // onError(e);
      // setIsLoading(false);
      console.log('### ERROR')
      console.log(e)
      console.log(e.code)
      console.log(e.message)
      // e.code could be "NotAuthorizedException" or 500 or 404 or...
      setIsLoading(false)
      setIsError(true)
    }
    return false
  }

  const RenderLogin = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2" action="#" method="POST">
        <h2 className="select-none mt-0 mb-6 text-center text-2xl font-bold text-gray-900">Sign in to your account</h2>
        <div>
          <label htmlFor="username" className="select-none block text-sm text-gray-400">
            Username
          </label>
          <div className="mt-1 shadow-md">
            <input
              {...register('username', { required: true })}
              readOnly={isLoading}
              className="appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-md"
              placeholder="Username"
              data-testid="login.input.username"
            />
          </div>
        </div>

        <div className="relative w-full mb-3">
          <PasswordInput
            registerFunc={register}
            isLoading={isLoading}
            inputName="password"
            testIds={{
              passwordInput: 'login.input.pw',
              eyeIcon: 'login.input.pw.eye'
            }}></PasswordInput>
        </div>

        <div className="text-right">
          <a
            onClick={() => setShowContent({ type: 'ResetPassword' })}
            className="cursor-pointer text-sm text-indigo-600 hover:text-indigo-400"
            data-testid="login.link.forgot_password"
          >
            Forgot Password?
          </a>
        </div>

        <div className="pt-0">
          <button
            type="submit"
            disabled={isLoading}
            className="select-none w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-12 disabled:opacity-50"
            data-testid="login.button.sign_in"
          >
            {isLoading
              ? <SvgLoader />
              : 'Sign in'
            }
          </button>
        </div>
        <ModalError
          open={isError}
          onClose={() => setIsError(false)}
          title="Authentication error"
          content="There was an error. Please verify your username and/or your password. If the error still occurs please get in contact with the support."
        />
      </form>
    )
  }

  const handleCodeIsSentCallback = (email) => {
    console.log('---- LOGIN handleCodeIsSentCallback emailAddress')
    console.log(email)
    setShowContent({
      type: 'VerifyCode',
      data: {
        email,
      }
    })
  }

  const showLoginPage = () => {
    setShowContent({ type: 'Login' })
  }

  const RenderSwitch = () => {
    switch (showContent.type) {
      case 'VerifyCode':
        return (
          <VerifyCode
            emailAddress={showContent?.data?.email}
            codeIsVerified={showLoginPage}
          />
        )

      case 'ResetPassword':
        return (
          <ResetPassword
            goBack={showLoginPage}
            codeIsSentCallback={handleCodeIsSentCallback}
          />
        )

      case 'SetPassword':
        return (
          <SetPassword
            onSuccess={showLoginPage}
            user={showContent?.data?.user}
          />
        )

      case 'Login':
      default:
        return <RenderLogin />
    }
  }

  return (
    <div className="min-h-screen flex flex-col justify-center pt-0 pb-10 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-3xl sm:rounded-lg sm:px-10 relative">
          <RenderSwitch />
        </div>
      </div>
    </div>
  )
}

export default Login
