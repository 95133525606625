import React from 'react'
import PropTypes from 'prop-types'
import RetinSightLogo from '../assets/svg/ris_logo_color_negative.svg'
import RetinSightPeople from '../assets/RetinSight-BG-People.jpg'

const LayoutFull = ({children}) => {
  return (
    <>
      <header className="bg-gray-800 fixed w-full h-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  src={RetinSightLogo}
                  className="h-14 w-30"
                  alt="RetInSight • Fluid Monitor Customer Portal"
                  data-testid="svg.logo"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <main
        // className="w-full bg-gray-400"
        className="hefm-full-layout-background"
        style={{
          background: `url(${RetinSightPeople}) no-repeat center center fixed`
        }}
      >
        {children}
      </main>
    </>
  )
}

LayoutFull.propTypes = {
  children: PropTypes.element.isRequired
}

export default LayoutFull
