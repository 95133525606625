import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ClipboardCopyIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { fn } from '../../utils/utils'

const CredentialsModalCreate = ({
  open = false,
  onClose = fn,
  onCreate = fn,
  credentials = null,
  content = 'Content of the modal',
  title = 'Modal Title',
  productName = 'product'
}) => {
  const createButtonRef = useRef()

  const {
    register,
    handleSubmit,
    reset
  } = useForm()

  useEffect(() => {
    if (open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])



  const RenderCustomerToken = ({ tokenType, token }) => {
    const [showCopyOverlay, setShowCopyOverlay] = React.useState(false)
    const [isTokenCopied, setIsTokenCopied] = React.useState(false)

    const DisplayMessage = ({ tokenType = '' }) => {
      return (
        <div className="absolute inset-0 flex justify-center items-center z-10 rounded-lg border bg-gray-700 bg-opacity-75">
          {isTokenCopied
            ? (
              <p className="text-white flex align-items-center"><CheckIcon className="h-6 w-6" aria-hidden="true" />Copied</p>
            )
            : (
              <p className="text-white">Click to copy {tokenType}</p>
            )
          }
        </div>
      )
    }

    DisplayMessage.propTypes = {
      tokenType: PropTypes.string,
    }

    return (
      <div
        className="relative rounded-lg border border-gray-300 px-4 py-3 shadow-sm flex items-center bg-gray-50"
        onClick={() => {
          navigator.clipboard.writeText(token)
          setIsTokenCopied(true)
        }}
      >
        <div className="flex-1 min-w-0">
          <button className="focus:outline-none" onMouseEnter={() => setShowCopyOverlay(true)} onMouseLeave={() => {
            setShowCopyOverlay(false)
            setIsTokenCopied(false)
          }}>
            {showCopyOverlay && <DisplayMessage tokenType={tokenType} />}
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-left text-sm text-gray-500 font-medium text-clip w-3/5 md:w-full my-2">{tokenType}</p>
            <p className="text-lg text-gray-700 truncate text-clip w-2/5 md:w-full my-2">{token}</p>
          </button>
        </div>
        <div className="text-gray-400">
          <ClipboardCopyIcon className="h-10 w-10 text-right" aria-hidden="true" />
        </div>
      </div>
    )
  }

  RenderCustomerToken.propTypes = {
    tokenType: PropTypes.string,
    token: PropTypes.string,
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {
                  credentials ?
                    <>
                      <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            Customer ID & Password generated
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 py-3 mb-2">
                              Please copy these credentials and paste them in the appropriate fields of the {productName}.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 sm:flex sm:items-start">
                        <div className="w-full">
                          <RenderCustomerToken tokenType="Customer ID" token={credentials.id}></RenderCustomerToken>
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:items-start">
                        <div className="w-full">
                          <RenderCustomerToken tokenType="Customer Password" token={credentials.appPassword}></RenderCustomerToken>
                        </div>
                      </div>
                    </> :
                    <form onSubmit={handleSubmit(onCreate)} className="space-y-2" action="#" method="POST">

                      <div className="sm:flex sm:items-start pb-5">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            {title}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 py-3 mb-2">
                              {content}
                            </p>
                            <div>
                              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Description
                              </label>
                              <div className="mt-1">
                                <input
                                  {...register('name', { required: true })}
                                  type="text"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  placeholder="My Site A"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => onCreate()}
                          ref={createButtonRef}
                        >
                          Continue
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

CredentialsModalCreate.propTypes = {
  open: PropTypes.bool,
  credentials: PropTypes.any,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string,
  productName: PropTypes.string,
}

export default CredentialsModalCreate
