import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import PrivacyDe from './PrivacyDe'
import PrivacyEn from './PrivacyEn'

const Privacy = ({
  children = null,
}) => {
  const { languageId } = useParams();

  return (
    <>
      <div className="w-full">
        <PageTitle title="Privacy Policy" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          { (languageId === 'en') ?
            (<PrivacyEn />)
            : (<></>)
          }
          { (languageId === 'de') ?
            (<PrivacyDe />)
            : (<></>)
          }
        </div>
      </div>
      {children}
    </>
  )
}

Privacy.propTypes = {
  children: PropTypes.any,
}

export default Privacy
