import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import PageTitle from '../../components/PageTitle'
import { classNames } from '../../utils/utils'
import { faqGroups } from './data'

const FAQ = ({
  children = null,
}) => {
  return (
    <>
      <div className="w-full faq">
        <PageTitle title="Frequently Asked Questions"/>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="p-5 mx-auto bg-white w-full shadow rounded-lg">
            <div className="px-2 md:px-5 mx-auto bg-white w-full flex flex-col md:flex-row">
              <div className="text-left w-full">
                <div>
                  <div className="mx-auto w-full">
                    {faqGroups.map((group) => (
                      <div className="mx-auto divide-y-2 divide-gray-200 py-6" key={group.groupName}>
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">{group.groupName}</h2>
                        <dl className="mt-10 space-y-4 divide-y divide-gray-200">
                          {group.faqs.map((faq) => (
                            <Disclosure as="div" key={`${group.groupName}_${faq.question}`} className="pt-4">
                              {({ open }) => (
                                <>
                                  <dt className="text-lg">
                                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                      <span className="text-base font-semibold leading-7">
                                        {faq.question}
                                      </span>
                                      <span className="ml-6 h-7 flex items-center">
                                        <ChevronDownIcon
                                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Disclosure.Button>
                                  </dt>
                                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                    <div className="text-base leading-7 text-gray-600">
                                      <ReactMarkdown className="markdown">{faq.answer}</ReactMarkdown>
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          ))}
                        </dl>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

FAQ.propTypes = {
  children: PropTypes.any,
}

export default FAQ
