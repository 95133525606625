import { createAsyncThunk } from '@reduxjs/toolkit'
import store from './store'

const authHeaders = () => {
  const token = store?.getState()?.user?.token
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token,
  }
  return headers
}


function createAppwayCredentials(endpoint) {
  return createAsyncThunk(
    'data/createAppwayCredentials',
    async (name, { rejectWithValue }) => {
      try {
        const response = await fetch(endpoint + `?name=${name}`, {
          headers: authHeaders(),
          method: 'POST',
          crossDomain: true,
        })
        if (!response.ok) {
          return rejectWithValue({ errorCode: response.status })
        }
        return await response.json()
      } catch (error) {
        console.log(error)
        if (!error.response) {
          throw error
        }

        return rejectWithValue(error.response.data)
      }
    }
  )
}

function getAppwayCredentials(endpoint) {
  return createAsyncThunk(
    'data/getAppwayCredentials',
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch(endpoint, {
          headers: authHeaders(),
          method: 'GET',
          crossDomain: true,
        })
        if (!response.ok) {
          return rejectWithValue({ errorCode: response.status })
        }
        return await response.json()
      } catch (error) {
        console.log(error)
        if (!error.response) {
          throw error
        }

        return rejectWithValue(error.response.data)
      }
    }
  )
}
function deleteAppwayCredentials(endpoint) {
  return createAsyncThunk(
    'data/deleteAppwayCredentials',
    async (id, { rejectWithValue }) => {
      try {
        const response = await fetch(endpoint + `?id=${id}`, {
          headers: authHeaders(),
          method: 'DELETE',
          crossDomain: true,
        })
        if (!response.ok) {
          return rejectWithValue({ errorCode: response.status })
        }
        return response.data
      } catch (error) {
        console.log(error)
        if (!error.response) {
          throw error
        }

        return rejectWithValue(error.response.data)
      }
    }
  )
}

const CREDENTIALS_ENDPOINT_URLS = {
  fluidMonitor: `${process.env.REACT_APP_API_HEFM_URL}/users/appway-credentials`,
  gaMonitor: `${process.env.REACT_APP_API_HEGM_URL}/users/appway-credentials`
}

export const createAppwayCredentialsFM = createAppwayCredentials(CREDENTIALS_ENDPOINT_URLS.fluidMonitor)
export const deleteAppwayCredentialsFM = deleteAppwayCredentials(CREDENTIALS_ENDPOINT_URLS.fluidMonitor)
export const getAppwayCredentialsFM = getAppwayCredentials(CREDENTIALS_ENDPOINT_URLS.fluidMonitor)

export const createAppwayCredentialsGM = createAppwayCredentials(CREDENTIALS_ENDPOINT_URLS.gaMonitor)
export const deleteAppwayCredentialsGM = deleteAppwayCredentials(CREDENTIALS_ENDPOINT_URLS.gaMonitor)
export const getAppwayCredentialsGM = getAppwayCredentials(CREDENTIALS_ENDPOINT_URLS.gaMonitor)

export const generateDownloadLink = createAsyncThunk(
  'data/generateDownloadLink',
  async (file, { rejectWithValue }) => {
    const token = store?.getState()?.user?.token
    if (!token) {
      return rejectWithValue('No token')
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    }
    const url = `${process.env.REACT_APP_API_PORTAL_URL}/api/generate-download-link?file=${file}`

    const response = await fetch(url, {
      headers,
      method: 'GET',
      crossDomain: true,
    })
    if (!response.ok) {
      return rejectWithValue('Request failed')
    }
    const data = await response.json()
    return data
  }
)