import { PaperClipIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import FluidMonitorLogo from '../../assets/svg/fluid_monitor_logo.svg';
import GAMonitorLogo from '../../assets/svg/ga_monitor_logo.svg';
import PageTitle from '../../components/PageTitle';
import { generateDownloadLink } from '../../store/DataSlice';

const InstructionsForUse = ({
  children = null,
}) => {
  const fmLanguagesList = [
    { 'shortName': 'fmIfuENGB', 'longName': 'English (GB)' },
    { 'shortName': 'fmIfuEN', 'longName': 'English (EU/CH)' },
    { 'shortName': 'fmIfuDE', 'longName': 'Deutsch (EU/CH)' },
    { 'shortName': 'fmIfuFR', 'longName': 'Français (EU/CH)' },
    { 'shortName': 'fmIfuIT', 'longName': 'Italiano (EU/CH)' },
    { 'shortName': 'fmIfuES', 'longName': 'Español (EU/CH)' }
  ];
  const gmLanguagesList = [
    { 'shortName': 'gmIfuENGB', 'longName': 'English (GB)' },
    { 'shortName': 'gmIfuEN', 'longName': 'English (EU/CH)' },
    { 'shortName': 'gmIfuDE', 'longName': 'Deutsch (EU/CH)' },
    { 'shortName': 'gmIfuFR', 'longName': 'Français (EU/CH)' },
    { 'shortName': 'gmIfuIT', 'longName': 'Italiano (EU/CH)' },
    { 'shortName': 'gmIfuES', 'longName': 'Español (EU/CH)' }
  ];
  const dispatch = useDispatch()

  async function openLanguage(shortName) {
    const { url } = await dispatch(generateDownloadLink(shortName)).unwrap();
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <div as="nav" className="w-full">
        <PageTitle title="Instruction for Use" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="p-5 sm:p-6 mx-auto bg-white w-full shadow rounded-lg">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-col flex-wrap sm:flex-nowrap">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12"
                      src={FluidMonitorLogo}
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-base text-left font-semibold text-gray-900">Fluid Monitor</h3>
                  </div>
                </div>
                <div className="mt-4 flex w-full">
                  <dd className="mt-2 text-sm text-white sm:col-span-2 sm:mt-0 w-full">
                    <ul className="divide-y divide-white/10 rounded-md border border-white/20">
                      {fmLanguagesList.map((language) => {
                        return (
                          <li key={language.shortName}
                            onClick={() => openLanguage(language.shortName)}
                            className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 cursor-pointer hover:bg-gray-100">
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium text-gray-900">{language.longName}</span>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </dd>
                </div>
              </div>
            </div>
          </div>
          <div className="my-5 p-5 sm:p-6 mx-auto bg-white w-full shadow rounded-lg">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-col flex-wrap sm:flex-nowrap">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12"
                      src={GAMonitorLogo}
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-base text-left font-semibold text-gray-900">GA Monitor</h3>
                  </div>
                </div>
                <div className="mt-4 flex w-full">
                  <dd className="mt-2 text-sm text-white sm:col-span-2 sm:mt-0 w-full">
                    <ul className="divide-y divide-white/10 rounded-md border border-white/20">
                      {gmLanguagesList.map((language) => {
                        return (
                          <li key={language.shortName}
                            onClick={() => openLanguage(language.shortName)}
                            className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 cursor-pointer hover:bg-gray-100">
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium text-gray-900">{language.longName}</span>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      {children}
    </>
  )
}

InstructionsForUse.propTypes = {
  children: PropTypes.any,
}

export default InstructionsForUse
