import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

const PasswordInput = ({
  registerFunc,
  isLoading = false,
  inputName = 'Password',
  label = 'Password',
  placeholder = 'Password',
  testIds = {
    passwordInput: 'password_input.pwd_filed',
    eyeIcon: 'password_input.eye_icon'
  },
}) => {
  const [isPwReadable, setIsPwReadable] = useState(false)

  return <>
    <label htmlFor={inputName} className="select-none text-sm text-gray-400">{label}</label>
    <div className="flex relative">
      <input
        {...registerFunc(inputName, { required: true })}
        name={inputName}
        readOnly={isLoading}
        className="appearance-none border border-gray-300 p-4 placeholder-gray-400 text-black bg-white rounded text-md shadow-md focus:outline-none focus:ring-indigo-500 w-full"
        placeholder={placeholder}
        type={isPwReadable ? 'text' : 'password'}
        data-testid={testIds.passwordInput}
      />
      <div
        className="absolute right-0 h-full pr-3 flex items-center text-sm"
        onClick={() => setIsPwReadable(!isPwReadable)}
        data-testid={testIds.eyeIcon}
      >
        <span className="group cursor-pointer">
          {isPwReadable
            ? <EyeOffIcon data-testid="svg.icon.eye_off" className="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-indigo-700" aria-hidden="true" />
            : <EyeIcon data-testid="svg.icon.eye" className="flex-shrink-0 h-5 w-5 text-gray-500 group-hover:text-indigo-700" aria-hidden="true" />
          }
        </span>
      </div>
    </div>
  </>
}

PasswordInput.propTypes = {
  registerFunc: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  testIds: PropTypes.shape({
    passwordInput: PropTypes.string,
    eyeIcon: PropTypes.string
  }),
}

export default PasswordInput