const faqGroups = [
  {
    groupName: 'Artificial Intelligence (AI) Development',
    faqs: [
      {
        question: 'What aspects of existing clinical practice does this system reinforce?',
        answer:
          'Supports clinicians by providing a measurement of fluid volume per fluid type (IRF, SRF and PED) which support clinicians by allowing them to track the response of patients in a quantitative manner.',
      },
      {
        question: 'Are the sizes of the training, validation, and test sets justified?',
        answer:
`Determining the right number of training samples in machine learning is not a trivial problem.
The total data set size must be chosen based on scope of the algorithm and variability in the data.
We not only need to think about the amount of data that we use, but also the variance in that data and if it is representative of the intended use population.

We gather input from clinical experts and literature reviews to understand the relevant pathologies or image-features which should be included in the data.
Initial train/validation set sizes are determined from a combination of expert knowledge and standards set which are reflected in the literature.
During development, if we find that certain pathologies or features introduce errors, we supplement the training data to reflect these issues.

When defining the test set size, we make sure that the set is large enough to yield statistically meaningful results and is representative of the intended use population.
Furthermore, in the test set we do not use any data from the patients that have been used in the training or validation set.`,
      },
      {
        question: 'How can we be sure the training data matches what we expect to see in real life and does not contain bias?',
        answer:
`One of the reasons that our algorithms are developed in close collaboration with the clinical experts is to address just such issues.
Understanding what data to use for training comes from an understanding the intended use population from a clinical perspective.
Bias can be avoided by understanding the relevant sources of bias/co-variates and using this knowledge to collect data and construct representative data splits.

The training, validation and test data were selected to be representative of the intended use population and relevant co-factors such as age, gender, race, and ethnicity.
We also take treatment status, disease stage into account and include potentially confounding pathologies were to ensure robustness to such features.
In addition, images with different qualities that might be captured in real life are included in the datasets.

Finally, the formal reporting test set (used for validation once model development has finished),
is selected from an independent data pool than that of the development (training and validation) to account for any bias from site/data source.
`,
      },
      {
        question: "How can we be confident of the quality of the 'labels' the system is trained on?",
        answer:
`To be confident in the quality of the annotations we work with we need to know a) that they represent the truth (as defined by clinical experts) and b) that human error is low.

To define the required annotations, an annotation protocol which draws on input from experts who are actively working in this field and peer reviewed publications.
We use validated high quality specialised annotation tools which are fit for purpose and allow annotators to delineate regions with high fidelity.

The annotations we use for training and evaluation (also known as the ground truth) are created by highly trained readers. Moreover, we have a two-stage review process.
Firstly, all annotations are reviewed by a clinical expert to ensure the annotations adhere to the annotation protocol.
Secondly, to ensure mistakes due to human error are caught we have a review process by the AI developers and expert graders,
this inter-disciplinary team discuss validity of annotations to ensure the labels/annotations are fit for purpose (i.e. training and validating the algorithm).
`,
      },
      {
        question: 'Was the A.I. algorithm trained using a standard of reference that is widely accepted in our field?',
        answer:
`The reference (also referred to as ground truth) we use to train and evaluate our algorithms are annotations made by expert readers and reviewed by clinical experts.
This is the typical way to develop a ground truth, especially when there is no predicate device or tool which may be used.

Readers make the annotations according to an annotation protocol which is written collaboratively by expert clinicians active in the field and algorithm developers.
For this purpose, we ask clinical experts who have worked in reading centers and been involved in annotation of phase II and phase III global clinical trials.
They draw on their own knowledge and peer reviewed papers to develop the annotation protocol.
In this way, their description of relevant features reflects what is widely accepted in the field.
`,
      },
      {
        question: 'Was the manner in which the A.I. algorithm makes decisions demonstrated?',
        answer:
`The model is a deep learning algorithm assigns each pixel in an image with a label (IRF, SRF, PED, or non-fluid) that results in a so-called segmentation map of each scan.
Based on this segmentation, the final measurement of fluid amount in nanolitres is calculated.
The precise internal logic of the segmentation algorithm is difficult to demonstrate due to the 'black-box' nature of deep learning.
This can be an overwhelming amount of data for complex networks and still does not give a clear answer as to how the segmentation algorithm makes decision.
However, we visualize the segmentation results of the A.I. algorithm, so that the users can check the basis of the fluid measurement calculation and judge to which extend they can rely on the final output.
`,
      },
      {
        question: 'Were the results of the A.I. algorithm compared with experts in my field?',
        answer:
`The ground truth against which the algorithm is evaluated is created by experts in the field.
Thus, the performance metrics show a comparison to experts in the field.
Moreover, the segmentation outputs of the A.I. algorithms are visualised and used in a qualitative evaluation process,
where predictions are discussed with clinical experts in the context of the input image and any other relevant metadata or labels.
`,
      },
      {
        question: 'Is the system applied to the same diagnostic context that it was trained in?',
        answer:
`Yes.
The algorithm was designed to be used by clinicians to support them during patient diagnosis and treatment in nAMD patients, in treatment naïve and treated patients.
The data collected for training and evaluating the model were selected to reflect the intended use.
`,
      },
      {
        question: 'Is the A.I. algorithm publicly available?',
        answer:
`No.
The A.I. algorithm itself is RetInSight's proprietary and confidential information and publicly not available.
`,
      },
      {
        question: 'How many b-scans should comprise the OCT volume?',
        answer:
`The higher the scanning density, the more fluid the OCT can capture and thus the higher the fluid volume reporting accuracy.
Reducing the scanning density (number of b-scans) is associated with increase in error in fluid volume estimate.
We have performed studies to estimate the error in fluid detection as a function of scanning density.

Two commonly used scanning densities are 49 and 97.
If you use 49 b-scans (as the minimum required section number by our tool) you will have an estimated error in fluid for IRF of 0.08 nanoliter (nl),
whereas using a 97-ans results would reduce the median error to 0.04 nanoliter.
With 97 slices the so-called median error in the 6mm ETDRS region is 0.04, 0.02 and 0.27 nl for IRF, SRF and PED, respectively,
whereas for 49 slices the median error is 0.08, 0.07 and 0.45 nl.
Error increases proportionally with fluid volume, this explains the larger error in PED - which typically has a greater presence than IRF or SRF within the OCT volume
`
      },
    ]
  },
  {
    groupName: 'Data Security',
    faqs: [
      {
        question: 'Does RetInSight encrypt patient health information data?',
        answer:
`Yes, RetInSights encrypts patient health information data to ensure the security throughout its lifecycle, both at rest and at transit.
This includes data storages, databases and backups.
The data is stored with Advanced Encryption Standard (AES) 256-bit encryption when at rest and only transferred using Transport Layer Security (SSL/TLS).`,
      },
      {
        question: 'Where is the patient health information stored?',
        answer:
`All biometric and health related customer data stored by RetInSight is in data centers hosted by Amazon Web Services (AWS) and certified according to ISO 27001.
These servers are housed separately from RetInSights corporate offices and are located only in the EU.
AWS will not transfer this data from RetInsights selected region and will only process customer data in accordance with RetInSight instructions.`,
      },
      {
        question: 'How does RetInSight keep the patient health information secure?',
        answer:
`RetInSight applies security best practices by using a combination of encryption, access controls, highly trained staff, and technical & organizational safeguards to protect our customers data.

This includes measures such as:
- Using up-to-date security software libraries, services, and measurements to protect our infrastructure and services
- Code reviews, security and data protection trainings and tracking changes of our infrastructure and applications
- Organizational and physical measures to protect our IT devices and infrastructure
  - Physical access control
    - No unauthorized access to data processing systems, e.g. magnetic or chip cards, keys, electric door openers, plant security or gatekeepers, alarm systems, video systems - RetInSight ensures Physical access control both in their offices as in two ISO 27001 certified external data centers.
  - Restricted access rights
    - By allowing only secure passwords, automatic locking mechanisms, two-factor authentication in certain areas, encryption of data media RetInSight ensures only authorized system use.
  - Access control
    - By an authorization concept and by needs-based access rights, and by logging of accesses we can avoid unauthorized reading, copying, modification or removal within the system.
  - Separation control
    - through separate processing of data collected for different purposes.
  - Pseudonymization
    - Especially when processing OCT scans, all PII data is processed in such a way that the data can no longer be assigned to a specific data subject.
  - Transmission control
    - No unauthorized reading, copying, modification or removal during electronic transmission or transport, due to encryption and Virtual Private Networks (VPN).
  - Availability control
    - Protection against accidental or willful destruction or loss, via backup strategy.
    - Regular backups of the biometric and health related customer data.
`,
      },
    ]
  },
  {
    groupName: 'Clinical',
    faqs: [
      {
        question: 'What do the three fluid biomarkers mean from an anatomical point of view?',
        answer:
`
- Intraretinal fluid (IRF): a round or elongated intraretinal hyporeflective space with clear boarder all around.
- Subretinal fluid (SRF): a hyporeflective space under the retinal tissue and above either the retinal pigment epithelium or a fibrotic tissue.
- Pigment epithelium detachment (PED): a space between the elevated retinal pigment epithelium and Bruch's membrane.
`,
      },
      {
        question: 'What does the Fluid Monitor result mean?',
        answer:
`
The measurements are realistic volumes of pathological fluid, which represents disease activity and response to therapy.
Fluid Monitor distinguishes between IRF, SRF and PED because these different fluid types have a different impact on vision.
A comparison of volumes over time allows to differentiate between active and degenerative fluid.
`,
      },
    ]
  },
  {
    groupName: 'Invoicing',
    faqs: [
      {
        question: 'When will we start paying for Fluid Monitor?',
        answer:
`All information regarding invoicing can be found in your Customer Agreement.
The first invoice you receive covers the Account Activation Fee.
It is invoiced after we have sent you the credentials for access to the RetInSight Fluid Monitor.
Next, your usage of Fluid Monitor will be invoiced, according to the agreed upon schedule.
`,
      },
      {
        question: 'Who can answer questions about the invoices we received?',
        answer:
`All information regarding invoicing can be found in your Customer Agreement.
Questions may be directed to our [accounting team](mailto:accounting@retinsight.com).
`,
      },
    ]
  },
  {
    groupName: 'Research',
    faqs: [
      {
        question: 'Can we include Fluid Monitor in one of our studies?',
        answer:
`If you are interested in conducting research with Fluid Monitor, please send a project summary with your request to our
[team](mailto:contact@retinsight.com).

Use of Fluid Monitor outside the scope of clinical routine is not covered by your Customer Agreement and therefore not permitted,
unless covered by the appropriate type of agreement between you and RetInSight.
`,
      },
    ]
  },
  {
    groupName: 'Sponsorship',
    faqs: [
      {
        question: 'Is RetInSight willing to sponsor our congress / symposium / ... ?',
        answer:
`RetInSight is a young company that has just obtained the CE-mark for its first pipeline product (Fluid Monitor).
Therefore, our ability to accept sponsorship invitation is limited.
Nevertheless, we will give each request careful consideration.
Please send your detailed request to our [team](mailto:contact@retinsight.com).
`,
      },
    ]
  },
]

export { faqGroups }
