import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../components/auth/store/userSlice'

const store = configureStore({
  reducer: {
    user: userReducer, // used for auth
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 250 },
    serializableCheck: { warnAfter: 250 },
  })
})

export default store
