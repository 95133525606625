import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

function ScrollToTop( props ) {
  const location = useLocation();
  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [location]);

  // eslint-disable-next-line react/prop-types
  return <>{props.children}</>
}

export default ScrollToTop;
