import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { fn } from '../../utils/utils'
import { /*ModalError,*/ ModalSuccess } from '../modals'
import SvgLoader from '../svg/Loader'
// import { timeout } from '../../utils/utils'

const ResetPassword = ({
  codeIsSentCallback = fn,
  goBack = fn,
}) => {
  // const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCodeSent, setIsCodeSent] = useState(false)

  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors }
  } = useForm()

  const onSubmit = async data => {
    // setIsLoading(true)
    // await timeout(3000)
    // setIsLoading(false)
    setIsLoading(true)
    try {
      await Auth.forgotPassword(data?.reset_username)
      setIsCodeSent(data?.reset_username)
    } catch (error) {
      // console.log("#### onSubmit ResetPassword ERROR")
      // console.log(error)
      // setIsError(true)
      // CTS-468: For security reasons: Always show a success message!
      setIsCodeSent(data?.reset_username)
    }
    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
      <div className="text-left">
        <a
          onClick={goBack}
          className="flex flex-row cursor-pointer text-sm text-indigo-600 hover:text-indigo-400"
          data-testid="login.resetpw.link.back"
        >
          <ArrowLeftIcon className="flex-shrink-0 h-5 w-5 " aria-hidden="true" /><span className="ml-1">Go Back</span>
        </a>
      </div>
      <h2 className="select-none mt-0 mb-6 text-center text-2xl font-bold text-gray-900">Reset password</h2>

      <div>
        <label htmlFor="username" className="select-none block text-sm text-gray-900">
          Please enter your email address in order to reset your password
        </label>
        <div className="mt-1 shadow-md">
          <input
            {...register('reset_username', { required: true })}
            readOnly={isLoading}
            className="appearance-none block w-full p-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-md"
            placeholder="Username"
            data-testid="login.input.reset.username"
          />
        </div>
      </div>

      <div className="pt-4">
        <button
          type="submit"
          disabled={isLoading}
          className="select-none w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-12 disabled:opacity-50"
          data-testid="login.button.reset"
        >
          { isLoading
            ? <SvgLoader />
            : 'Send Confirmation'
          }
        </button>
      </div>
      {/* The error modal is not shown anymore */}
      {/* <ModalError
        open={isError}
        onClose={() => setIsError(false)}
        title="Password reset error"
        content="There was an error. Please verify your username or contact the support."
      /> */}
      <ModalSuccess
        open={!!isCodeSent}
        onClose={() => codeIsSentCallback(isCodeSent)}
        title="Password reset code sent"
        content={
          <div>
            <h3>An email is sent to you if you’re a registered user.</h3>
            <div className="my-2 font-bold">Please check your email for the confirmation code!</div>
            <div>If you didn’t get the confirmation code please get in contact with the support.</div>
          </div>
        }
      />
    </form>
  )
}

ResetPassword.propTypes = {
  codeIsSentCallback: PropTypes.func,
  goBack: PropTypes.func,
}

export default ResetPassword
